 <template>
  <section v-loading="listLoading">
    <div class="financialRecordDialog" v-if="info">
      <div class="titleRecord">
        <span style="font-weight: bold" v-if="info.financeTask"
          >[{{ info.projectName }}][{{ info.resumeName }}]_{{
            info.financeTask.invoiceType+'-'+ info.financeTask.payType
          }}</span
        >
        <span
          v-if="info.financeTask"
          style="float: right; font-size: 12px; color: #999"
          >创建人：<span style="font-size: 12px; color: #666">{{
            info.financeTask.creatorName
          }}</span>
          <el-divider direction="vertical"></el-divider>
          创建时间：<span style="font-size: 12px; color: #666">{{
            info.financeTask.createdTime
          }}</span></span
        >
      </div>
      <el-row :gutter="20" :span="24">
        <el-col :span="17" style="padding-bottom: 55px">
          <el-collapse accordion v-model="activeNames">
            <!--            退款-->
            <el-collapse-item
              title="退款信息"
              name="11"
              v-if="(info.refunds || []).length > 0"
            >
              <el-collapse accordion>
                <el-collapse-item
                  v-for="(item, inx) in info.refunds"
                  :key="inx"
                  style="position: relative"
                >
                  <template slot="title">
                    退款信息( {{ item.accountDate }} )
                    <template v-if="handleFinance">
                      <span class="contral">
                        <i
                          class="iconfont icon-kh_gsxq_bj"
                          @click.stop="
                            (refundForm = item),
                              (isCanRefund = true),
                              (refundTitle = '编辑退款')
                          "
                        ></i>
                        <i
                          class="el-icon-delete"
                          @click.stop="delRefund(item.id)"
                        ></i>
                      </span>
                    </template>
                  </template>
                  <el-form label-width="120px" label-position="left">
                    <el-row>
                      <el-col :span="11">
                        <el-form-item label="退款金额：">{{
                          item.accountMoney
                        }}</el-form-item></el-col
                      >
                      <el-col :offset="1" :span="11"
                        ><el-form-item label="退款时间：">
                          {{ item.accountDate }}</el-form-item
                        ></el-col
                      >
                      <el-col :span="11"
                        ><el-form-item label="业绩归属时间：">
                          {{ item.performanceAttributionDate }}</el-form-item
                        ></el-col
                      >
                      <el-col :span="24"
                        ><el-form-item label="退款备注：">
                          {{ item.remark }}</el-form-item
                        ></el-col
                      >
                    </el-row>
                  </el-form>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
            <!--            到账信息-->
            <el-collapse-item
              v-if="(info.accountRecords || []).length > 0"
              title="到账信息"
              name="5"
            >
              <template>
                <el-collapse accordion>
                  <el-collapse-item
                    v-for="(item, inx) in info.accountRecords"
                    :key="inx"
                    style="position: relative"
                  >
                    <template slot="title">
                      到账金额( {{ item.accountDate }} )
                      <template v-if="handleFinance">
                        <span class="contral">
                          <i
                            class="iconfont icon-kh_gsxq_bj"
                            v-if="
                              info.financeTask.accountMoney == 0 ||
                              info.financeTask.accountMoney
                            "
                            @click.stop="editAccount(item)"
                          ></i>
                          <i
                            class="el-icon-delete"
                            @click.stop="delAccontMoney(item.id)"
                          ></i>
                        </span>
                      </template>
                    </template>
                    <el-form label-width="120px" label-position="left">
                      <el-row>
                        <el-col :span="11">
                          <el-form-item label="到账金额：">{{
                            item.accountMoney
                          }}</el-form-item></el-col
                        >
                        <el-col :offset="1" :span="11"
                          ><el-form-item label="到账时间：">
                            {{ item.accountDate }}</el-form-item
                          ></el-col
                        >
                        <el-col :span="11"
                          ><el-form-item label="业绩归属时间：">
                            {{ item.performanceAttributionDate }}</el-form-item
                          ></el-col
                        >
                        <el-col :span="24"
                          ><el-form-item label="到账备注：">
                            {{ item.remark }}</el-form-item
                          ></el-col
                        >
                        
                      </el-row>
                    </el-form>
                  </el-collapse-item>
                </el-collapse>
              </template>
            </el-collapse-item>
            <!--            发票信息-->
            <el-collapse-item
              name="1"
              v-if="info.signCompanyName"
              style="position: relative"
            >
              <template slot="title">
                发票信息
                <span class="contral">
                  <i
                    class="iconfont icon-kh_gsxq_bj"
                    v-if="handleFinance"
                    @click.stop="editInvoiceInfo(info.financeTask)"
                  ></i>
                </span>
              </template>
              <el-form label-width="110px" label-position="left">
                <el-row>
                  <el-col :span="11">
                    <el-form-item label="开票公司：">{{
                      info.signCompanyName
                    }}</el-form-item></el-col
                  >
                  <el-col :offset="1" :span="11"
                    ><el-form-item label="开票客户：">
                      {{ info.financeTask.invoiceCompany }}</el-form-item
                    ></el-col
                  >
                  <el-col :span="11">
                    <el-form-item label="发票号：">{{
                      info.financeTask.invoiceNumber
                    }}</el-form-item></el-col
                  >
                  <el-col :offset="1" :span="11"
                    ><el-form-item label="不计税金额：">
                      {{ info.financeTask.noTaxMoney }}</el-form-item
                    ></el-col
                  >
                  <el-col :span="11">
                    <el-form-item label="实际开票时间：">{{
                      info.financeTask.actualMakeInvoiceDate
                    }}</el-form-item></el-col
                  >
                  <el-col :span="11">
                    <el-form-item label="业绩归属时间：">{{
                      info.financeTask.performanceAttributionDate
                    }}</el-form-item></el-col
                  >
                  <el-col :span="24"
                    ><el-form-item label="备注：">
                      {{ info.financeTask.invoiceRemark }}</el-form-item
                    ></el-col
                  >
                </el-row>
              </el-form>
            </el-collapse-item>
            <!--            开票信息-->
            <el-collapse-item title="开票申请" name="2" v-if="info.financeTask">
              <el-form label-width="110px" label-position="left">
                <el-row>
                  <el-col :span="11">
                    <el-form-item label="开票类型：">{{
                      info.financeTask.invoiceType+'-'+info.financeTask.payType
                    }}</el-form-item></el-col
                  >
                  <el-col :offset="1" :span="11"
                    ><el-form-item label="服务费用：">
                      {{ info.serviceMoney }}</el-form-item
                    ></el-col
                  >
                  <el-col :span="11">
                    <el-form-item label="开票金额：">{{
                      info.financeTask.payAmount
                    }}</el-form-item></el-col
                  >
                  <el-col :offset="1" :span="11"
                    ><el-form-item label="约定开票时间：">
                      {{
                        info.financeTask.conventionMakeInvoiceDate
                          ? info.financeTask.conventionMakeInvoiceDate.replace(
                              RegExp("/", "g"),
                              "-"
                            )
                          : "-"
                      }}</el-form-item
                    ></el-col
                  >
                  <el-col :span="11">
                    <el-form-item label="约定到账时间：">{{
                      info.financeTask.conventionToAccountDate
                        ? info.financeTask.conventionToAccountDate.replace(
                            RegExp("/", "g"),
                            "-"
                          )
                        : "-"
                    }}</el-form-item></el-col
                  >
                  <el-col :offset="1" :span="11"
                    ><el-form-item label="付款通知书：">
                      <span
                        class="tabHref mgr10"
                        @click="handleDown(item)"
                        v-for="(item, index) in info.financeTask
                          .payNoticeFileList"
                        :key="index"
                        >{{ item.fileName }}</span
                      ></el-form-item
                    ></el-col
                  >
                  <el-col :span="24"
                    ><el-form-item label="开票备注：">
                      {{ info.financeTask.remark }}</el-form-item
                    ></el-col
                  >
                  <el-col :span="24" v-if="!info.financeTask.noContract">
                    <el-form-item label="合同名称：">{{
                      info.financeTask.contract.name
                        ? info.financeTask.contract.name
                        : "-"
                    }}</el-form-item></el-col
                  >
                  <el-col :span="11" v-if="!info.financeTask.noContract">
                    <el-form-item label="合同状态：">
                      {{
                        info.financeTask.contract.status
                          ? info.financeTask.contract.status
                          : "-"
                      }}</el-form-item
                    ></el-col
                  >
                  <el-col
                    :offset="1"
                    :span="11"
                    v-if="!info.financeTask.noContract"
                  >
                    <el-form-item label="付款周期：">{{
                      info.financeTask.contract.paymentCycle
                        ? info.financeTask.contract.paymentCycle
                        : "-"
                    }}</el-form-item>
                  </el-col>
                  <el-col :span="24" v-if="!info.financeTask.noContract"
                    ><el-form-item label="合同客户名称：">
                      <p
                        v-if="info.financeTask.contract.companyName.length > 0"
                        style="margin-top: 0; margin-right: 10px"
                        v-for="(i, index) in info.financeTask.contract
                          .companyName"
                        :key="index"
                      >
                        {{ i ? i : "-" }}
                      </p>
                      <p v-if="info.financeTask.contract.companyName == null">
                        -
                      </p>
                    </el-form-item></el-col
                  >
                  <el-col :span="11" v-if="!info.financeTask.noContract">
                    <el-form-item label="合同我司名称：">{{
                      info.financeTask.contract.signCompanyName
                        ? info.financeTask.contract.signCompanyName
                        : "-"
                    }}</el-form-item></el-col
                  >
                  <el-col
                    :offset="1"
                    :span="11"
                    v-if="!info.financeTask.noContract"
                  >
                    <el-form-item label="合同有效期："
                      >{{
                        info.financeTask.contract.startDate
                          ? info.financeTask.contract.startDate.replace(
                              RegExp("/", "g"),
                              "-"
                            )
                          : ""
                      }}
                      至
                      {{
                        info.financeTask.contract.endDate
                          ? info.financeTask.contract.endDate.replace(
                              RegExp("/", "g"),
                              "-"
                            )
                          : "无期限"
                      }}</el-form-item
                    ></el-col
                  >
                  <el-col :span="24" v-if="!info.financeTask.noContract">
                    <el-form-item label="合同附件：">
                      <span
                        class="tabHref mgr10"
                        v-for="(item, index) in JSON.parse(
                          info.financeTask.contract.filePath
                        )"
                        :key="index"
                        ><span @click="previewOpen(item)"
                          >{{ item.fileName }}
                        </span>
                        <i
                          @click="handleDown(item)"
                          class="el-icon-download"
                          style="margin-left: 15px"
                        ></i
                      ></span>
                      <p v-if="info.financeTask.contract.filePath == null">-</p>
                    </el-form-item></el-col
                  >
                  
                  <el-col :span="24" v-if="info.financeTask.noContract" >
                    <p
                      style="
                        border: 1px solid rgb(255, 221, 220);
                        padding: 10px 20px;
                        color: rgb(204, 102, 102) !important;
                        background-color: rgb(255, 245, 244);
                        margin: 0px 0px 10px;
                        line-height: normal;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <span>本次开票申请属【无合同开票】</span>
                      <span
                        v-if="
                          info.financeTask.totalNoContractApplyByCustomer > 0
                        "
                        class="tabHref"
                        style="margin: 0 8px"
                        @click="showHistory"
                        >客户共{{
                          info.financeTask.totalNoContractApplyByCustomer
                        }}次历史申请记录</span
                      >
                    </p>
                  </el-col>
                  <el-col :span="24" v-if="info.financeTask.noContract">
                    <el-form-item label="证明材料：">
                      <span
                        class="tabHref mgr10"
                        v-for="(item, index) in info.financeTask
                          .noContractProveFiles"
                        :key="index"
                        ><span @click="previewOpen(item)"
                          >{{ item.fileName }}
                        </span>
                        <i
                          @click="handleDown(item)"
                          class="el-icon-download"
                          style="margin-left: 15px"
                        ></i
                      ></span>
                      <p v-if="info.financeTask.noContractProveFiles == null">
                        -
                      </p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" v-if="info.financeTask.noContract">
                    <el-form-item label="情况说明：">
                      {{
                        info.financeTask.noContractComment
                          ? info.financeTask.noContractComment
                          : "-"
                      }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" v-if="info.financeTask.noContract">
                    <el-form-item label="审批情况：">
                      通过
                      <span
                        class="tabHref"
                        style="margin-left: 8px"
                        @click="showApprovalFlow"
                        >查看审批流</span
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-collapse-item>

            <!--            分配方案-->
            <el-collapse-item title="分配方案" name="3">
              <el-collapse>
                <el-collapse-item
                  v-for="(item, index) in info.performanceAllocationList"
                  :key="index"
                >
                  <template slot="title">
                    {{ item.roleName }}：{{ item.userName }} ({{
                      item.percent
                    }}%)
                  </template>
                  <el-form label-width="60px" label-position="left">
                    <el-row>
                      <el-col :span="11">
                        <el-form-item label="集团：">
                          <span
                            v-for="(_item, idx) in item.allocationItems
                              ? item.allocationItems.groups
                              : []"
                            :key="idx"
                            >{{ _item.departmentName }} -
                            {{ _item.percent }}%<span
                              v-if="
                                item.allocationItems.groups &&
                                idx != item.allocationItems.groups.length - 1
                              "
                              >、</span
                            ></span
                          >
                        </el-form-item>
                      </el-col>
                      <el-col :offset="1" :span="11">
                        <el-form-item label="地区：">
                          <span
                            v-for="(_item, idx) in item.allocationItems
                              ? item.allocationItems.areas
                              : []"
                            :key="idx"
                            >{{ _item.departmentName }} -
                            {{ _item.percent }}%<span
                              v-if="
                                item.allocationItems.areas &&
                                idx != item.allocationItems.areas.length - 1
                              "
                              >、</span
                            ></span
                          >
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="11">
                        <el-form-item label="团队：">
                          <span
                            v-for="(_item, idx) in item.allocationItems
                              ? item.allocationItems.teams
                              : []"
                            :key="idx"
                            >{{ _item.departmentName }} -
                            {{ _item.percent }}%<span
                              v-if="
                                item.allocationItems.teams &&
                                idx != item.allocationItems.teams.length - 1
                              "
                              >、</span
                            ></span
                          >
                        </el-form-item>
                      </el-col>
                      <el-col :offset="1" :span="11">
                        <el-form-item label="子团队：">
                          <span
                            v-for="(_item, idx) in item.allocationItems
                              ? item.allocationItems.subTeams
                              : []"
                            :key="idx"
                            >{{ _item.departmentName }} -
                            {{ _item.percent }}%<span
                              v-if="
                                item.allocationItems.subTeams &&
                                idx != item.allocationItems.subTeams.length - 1
                              "
                              >、</span
                            ></span
                          >
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
          </el-collapse>
        </el-col>
        <el-col :span="7" style="padding-left: 0">
          <div class="canRecordBox">
            <div class="canRecord">操作记录</div>
            <el-timeline class="canRecordcon">
              <el-timeline-item
                v-for="(activity, index) in info.approvalResultList"
                :key="index"
                :icon="activity.icon"
                :type="activity.type"
                :color="activity.color"
                :size="activity.size"
              >
                <div class="approvalTime">{{ activity.approvalTime }}</div>
                <div style="color: #606266">{{ activity.resultString }}</div>
                <div
                  v-if="activity.remark"
                  class="clearfix"
                  style="color: #606266"
                >
                  <span class="fl" style="display: inline-block">{{
                    activity.status == "驳回" ? "驳回理由：" : "备注："
                  }}</span>
                  <div
                    class="fl"
                    style="width: calc(100% - 65px); color: #333"
                    v-html="activity.remark"
                  ></div>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-col>
      </el-row>
    </div>
    <div
      class="operateBtn"
      v-has="'finance:index:done'"
      v-if="info && handleFinance"
    >
      <el-button
        type="primary"
        plain
        size="mini"
        @click="handleInvoice"
        v-if="info.canCompleteInvoice||info.canReverse"
        >{{info.canCompleteInvoice?'完善开票信息':'完善红冲信息'}}</el-button
      >
      <el-button
        type="primary"
        plain
        size="mini"
        @click="
          improveFoo(),
            (titleName = '完善到账信息'),
            handleCancel('form1'),
            (version = false)
        "
        v-if="info.canCompleteFinance"
        >完善到账信息</el-button
      >
      <el-button
        type="primary"
        plain
        size="mini"
        @click="(isRejectDialog = true), clearForm()"
        v-if="info.canReject"
        >驳回</el-button
      >
      <!-- <el-button
        type="primary"
        plain
        size="mini"
        v-if="info.canFail"
        @click="(isCanFail = true), clearForm()"
        >作废</el-button
      > -->
      <!-- <el-button
        type="primary"
        plain
        size="mini"
        v-if="info.canReverse"
        @click="
          resetFoo(),
            clearForm(),
            (isRest = true),
            (improveBuildInfoTitle = '红冲')
        "
        >红冲</el-button
      > -->
      <el-button
        type="primary"
        plain
        size="mini"
        v-if="info.canRefund"
        @click="CanRefund(), clearForm(), (refundTitle = '完善退款信息')"
        >完善退款信息</el-button
      >
      <el-button
        type="primary"
        plain
        @click="cancelFinanceTask()"
        v-if="info.canCancel"
        >取消</el-button
      >
    </div>
    <div class="operateBtn" v-else>
      <el-button
        type="primary"
        plain
        size="mini"
        @click="reset(), (titName = '调整发起')"
        v-if="info && info.canEdit"
        >调整发起</el-button
      >
    </div>
    <!--退款-->
    <el-dialog
      :title="refundTitle"
      :visible.sync="isCanRefund"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      width="600px"
      :before-close="handleClose"
      class="invoiceNumberInput"
    >
      <el-form
        :model="refundForm"
        ref="refundForm"
        label-width="120px"
        class="clearfix"
        :rules="rules"
      >
        <el-col :span="21">
          <el-form-item label="退款金额：" prop="accountMoney">
            <el-input
              placeholder="请务必填写正数"
              type="number"
              v-model="refundForm.accountMoney"
              @mousewheel.native.prevent
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="21">
          <el-form-item label="退款时间：" prop="accountDate">
            <el-date-picker
              style="width: 100%"
              v-model="refundForm.accountDate"
              type="date"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="21">
          <el-form-item label="业绩归属时间：" prop="performanceAttributionDate">
            <el-date-picker
              style="width: 100%"
              v-model="refundForm.performanceAttributionDate"
              type="date"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="21">
          <el-form-item label="退款备注：">
            <el-input v-model="refundForm.remark" type="textarea"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" plain size="mini" @click="isCanRefund = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveInfoloading"
          @click="saveRefund()"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!--    完善开票信息 红冲-->
    <el-dialog
      :title="improveBuildInfoTitle"
      :visible.sync="isCanCompleteInvoice"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      width="600px"
      destroy-on-close
      :before-close="handleClose"
      class="invoiceNumberInput"
    >
      <!-- 完善开票信息 红冲  -->

      <el-form
        :model="form"
        ref="form"
        label-width="120px"
        class="clearfix"
        :rules="rules"
        v-if="canCompleteInvoice"
      >
        <el-col :span="21">
          <el-form-item label="开票公司：" prop="signCompanyId">
            <el-select
              v-model="form.signCompanyId"
              collapse-tags
              filterable
              placeholder="请选择"
              ref="permissionRef1"
              style="width: 100%"
            >
              <el-option
                v-for="item in companyBaseInfoList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="21" v-if="!isRest">
          <el-form-item label="开票客户：" prop="invoiceCompany">
            <el-input
              placeholder="开票客户"
              maxlength="200"
              v-model.trim="form.invoiceCompany"
              @mousewheel.native.prevent
              @input="change($event)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="21" v-if="isRest">
          <el-form-item label="计税金额：" prop="amount">
            <el-input
              placeholder="计税金额"
              v-model="form.amount"
              type="number"
              @input="change($event)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="21">
          <el-form-item label="不计税金额：" prop="noTaxMoney">
            <el-input
              placeholder="不计税金额"
              v-model="form.noTaxMoney"
              type="number"
              maxlength="10"
              oninput="if(value.length > 11) value=value.slice(0, 11)"
              @mousewheel.native.prevent
              @input="change($event)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="21">
          <el-form-item label="发票号：" prop="invoiceNumber">
            <el-input
              placeholder="开票发票号"
              maxlength="50"
              v-model.trim="form.invoiceNumber"
              @mousewheel.native.prevent
              @input="change($event)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="21">
          <el-form-item label="实际开票时间：" prop="actualMakeInvoiceDate">
            <el-date-picker
              style="width: 100%"
              v-model="form.actualMakeInvoiceDate"
              type="date"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="21">
          <el-form-item label="业绩归属时间：" prop="performanceAttributionDate">
            <el-date-picker
              style="width: 100%"
              v-model="form.performanceAttributionDate"
              type="date"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="21">
          <el-form-item label="备注：">
            <el-input
              :rows="3"
              maxlength="100"
              v-model="form.invoiceRemark"
              @input="change($event)"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="isCanCompleteInvoice = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveInfoloading"
          @click="saveCanCompleteInvoice"
          >保存</el-button
        >
      </span>
    </el-dialog>

    <!--    完善到账信息-->
    <el-dialog
      :title="titleName"
      :visible.sync="isCanCompleteFinance"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      width="600px"
      destroy-on-close
      :before-close="handleClose"
      class="invoiceNumberInput clearfix"
    >
      <!-- 完善到账信息 -->
      <el-form
        :model="form"
        ref="form1"
        class="clearfix"
        label-width="120px"
        :rules="rules1"
        v-if="isCanCompleteFinance"
      >
        <el-col :span="21">
          <el-form-item
            label="到账金额："
            label-width="120px"
            prop="entryMoney"
          >
            <el-input
              maxlength="11"
              type="number"
              @mousewheel.native.prevent
              v-model="form.entryMoney"
              @input="change($event)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="21">
          <el-form-item
            label="实际到账时间："
            label-width="120px"
            prop="actualAccountDate"
          >
            <el-date-picker
              style="width: 100%"
              v-model="form.actualAccountDate"
              type="date"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="21">
          <el-form-item
            label="业绩归属时间："
            label-width="120px"
            prop="performanceAttributionDate"
          >
            <el-date-picker
              style="width: 100%"
              v-model="form.performanceAttributionDate"
              type="date"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="21">
          <el-form-item label="备注：" label-width="120px">
            <el-input
              type="textarea"
              maxlength="100"
              v-model="form.accountRemark"
              @input="change($event)"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="(isCanCompleteFinance = false), handleCancel('form1')"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveInfoloading"
          @click="saveImproveFoo"
          >保存</el-button
        >
      </span>
    </el-dialog>

    <!--    作废-->
    <el-dialog
      title="作废"
      :visible.sync="isCanFail"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      width="600px"
      :before-close="handleClose"
      class="invoiceNumberInput"
    >
      <el-form
        @submit.native.prevent
        label-width="100px"
        :model="form"
        ref="form"
      >
        <el-form-item
          label="作废理由："
          prop="invalid"
          :rules="{
            required: true,
            message: '请输入作废理由',
            trigger: 'blur',
          }"
        >
          <el-input
            type="textarea"
            maxlength="100"
            :rows="3"
            placeholder="请输入作废理由"
            v-model.trim="form.invalid"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" plain size="mini" @click="isCanFail = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveInfoloading"
          @click="saveInvalid()"
          >保存</el-button
        >
      </span>
    </el-dialog>

    <!--    驳回-->
    <el-dialog
      title="驳回"
      :visible.sync="isRejectDialog"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      width="600px"
      :before-close="handleClose"
      class="invoiceNumberInput"
    >
      <!--      驳回-->
      <el-form
        @submit.native.prevent
        label-width="100px"
        :model="form"
        ref="form"
      >
        <el-form-item
          label="驳回理由："
          prop="remark"
          :rules="{
            required: true,
            message: '请输入驳回理由',
            trigger: 'blur',
          }"
        >
          <el-input
            type="textarea"
            maxlength="100"
            :rows="3"
            placeholder="请输入驳回理由"
            v-model.trim="form.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="isRejectDialog = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveInfoloading"
          @click="SaveReject()"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 重新发起 -->
    <el-drawer
      :visible.sync="dialogVisible"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-pl_cjcw"></i>重新发起({{
          info.resumeName
        }})
      </div>
      <financialTasks
        v-if="isfinancialTask"
        ref="saveFinancialTask"
        :projectResumeId="projectResumeId"
        :financeTaskId="financeTaskId"
        :companyId="info.companyId"
        @changeSaveLoading="changeSaveLoading"
        @loadList="(dialogVisible = false), cancel(), getDetail()"
      ></financialTasks>
      <div style="float: right; margin: 0 15px">
        <el-button type="default" plain size="mini" @click="cancle()"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveInfoloading"
          @click="saveInfos"
          >保存</el-button
        >
      </div>
    </el-drawer>
    <!--    查看审批流-->
    <el-dialog
      title="审批流程"
      :visible.sync="approvalFlowDig"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      width="600px"
      class="invoiceNumberInput"
    >
      <div style="margin-left: 80px">
        <el-timeline class="canRecordcon">
          <el-timeline-item
            v-for="(activity, index) in approvalFlowList"
            :key="index"
            :icon="activity.icon"
            :type="activity.type"
            :color="activity.color"
            :size="activity.size"
          >
            <div class="approvalTime">{{ activity.approvalTime }}</div>
            <div style="color: #606266">{{ activity.content }}</div>
            <div
              v-if="activity.comment"
              class="clearfix"
              style="color: #526ecc"
            >
              <span class="fl" style="display: inline-block">备注：</span>
              <div
                class="fl"
                style="width: calc(100% - 65px); color: #526ecc"
                v-html="activity.comment"
              ></div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
    <!-- 历史记录 -->
    <el-drawer
      :visible.sync="isShowHistory"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">历史申请记录</div>
      <div class="drawerCon">
        <history
          ref="history"
          v-if="isShowHistory"
          :projectResumeId="projectResumeId"
          :applyId="info.financeTask.noContractApplyId"
        ></history>
      </div>
    </el-drawer>
  </section>
</template>
<script>
import Cookies from "js-cookie";
import {
  addRefund,
  delAccontMoney,
  editRefund,
  deleteRefund,
  getFinanceDetail,
  approvalFinanceApproval,
  getCommonSignCompanyList,
  completeInvoice,
  editInvoice,
  reserveSave,
  deleteReverse,
  completeAccount,
  editFinanceAccountMoneyRecord,
  approvalCancel,
  editAccountMoney,
  deleteValidate, //删除红冲校验
  delAccontMoneyValidate, //删除到账记录校验
  noContractFinanceApprovalFlow, //查看无合同开票审批流
} from "../../api/api";
import financialTasks from "../pipeline/financialTasks";
import * as api from "../../api/http";
import SpecialCom from "../../views/setting/specialCom";
import history from "../../components/finance/history";
var d = new Date();
var year = d.getFullYear();
var month = d.getMonth() + 1;
var day = d.getDate();
if (month >= 1 && month <= 9) {
  month = "0" + month;
}
if (day >= 1 && day <= 9) {
  day = "0" + day;
}
const date = year + "-" + month + "-" + day;
export default {
  props: ["financeTaskId", "isFinance", "handleFinance", "projectResumeId"],
  components: {
    financialTasks,
    SpecialCom,
    history,
  },
  data() {
    const isPriceVlidator = (rule, value, callback) => {
      const pattern = /^\d+.?\d{0,2}$/;
      if ((value + "").length > 10) {
        return callback(new Error("不能超过11位"));
      } else if ((value + "").length <= 10 && !pattern.test(value)) {
        return callback(new Error("输入大于0且最多保留两位小数"));
      } else return callback();
    };

    return {
      refundForm: {
        accountDate: date,
        accountMoney: "",
        performanceAttributionDate:date,
        financeTaskId: "",
        id: "",
        remark: "",
        version: 0,
      },
      refundTitle: "退款",
      canCompleteInvoice: false,
      improveBuildInfoTitle: "完善开票信息",
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      actionUrl: null,
      titleName: null,
      info: {},
      version: false,
      isCanRefund: false,
      listLoading: false,
      isCanFail: false,
      isRest: false,
      editInvoice: false,
      ApiBaseUrl: api.ApiBaseUrl,
      dialogVisible: false,
      form: {
        signCompanyId: null,
        invoiceNumber: null,
        invoiceRemark: null,
        accountRemark: null,
        actualMakeInvoiceDate: date,
        performanceAttributionDate:date,
        actualAccountDate: date,
        accountDate: date,
        invoiceCompany: null,
        financeTaskId: null,
        accountMoney: null,
        remark: null,
        version: 0,
        noTaxMoney: null,
        amount: null,
        invalid: null,
        gllueId: null,
        id: null,
        entryMoney: null,
      },
      companyBaseInfoList: [],
      accountInformationData: [],
      titName: "",
      imageUrl: null,
      isCompleteInvoiceAndAccount: false,
      saveInfoloading: false,
      rules: {
        signCompanyId: [
          { required: true, message: "请选择开票公司", trigger: "change" },
        ],
        noTaxMoney: [
          { required: true, message: "请输入不计税金额", trigger: "blur" },
          { validator: isPriceVlidator, trigger: "blur" },
        ],
        amount: [
          { required: true, message: "请输入计税金额", trigger: "blur" },
          { validator: isPriceVlidator, trigger: "blur" },
        ],
        invoiceCompany: [
          { required: true, message: "请输入开票客户", trigger: "blur" },
        ],
        invoiceNumber: [
          { required: true, message: "请输入发票号", trigger: "blur" },
        ],
        actualMakeInvoiceDate: [
          { required: true, message: "请选择实际开票时间", trigger: "change" },
        ],
        performanceAttributionDate: [
          { required: true, message: "请选择业绩归属时间", trigger: "change" },
        ],
        actualAccountDate: [
          { required: true, message: "请选择实际到账时间", trigger: "change" },
        ],
        accountMoney: [
          { required: true, message: "请输入退款金额", trigger: "blur" },
          { validator: isPriceVlidator, trigger: "blur" },
        ],
        accountDate: [
          { required: true, message: "请选择退款时间", trigger: "change" },
        ],
        entryMoney: [
          { required: true, message: "请输入到账金额", trigger: "blur" },
          { validator: isPriceVlidator, trigger: "blur" },
        ],
      },
      rules1: {
        actualAccountDate: [
          { required: true, message: "请选择实际到账时间", trigger: "change" },
        ],
        performanceAttributionDate: [
          { required: true, message: "请选择业绩归属时间", trigger: "change" },
        ],
        entryMoney: [
          { required: true, message: "请输入到账金额", trigger: "blur" },
          { validator: isPriceVlidator, trigger: "blur" },
        ],
      },
      fileList: [],
      isReject: false,
      isRejectDialog: false,
      invalid: false,
      isfinancialTask: false,
      isCanCompleteInvoice: false,
      accountInformationDialog: false,
      isCanCompleteFinance: false,
      canCompleteFinance: false,
      activeNameReverse: null,
      activeNamerefunds: 1,
      activeNames: ["2"],
      approvalFlowDig: false,
      approvalFlowList: [],
      isShowHistory: false,
      projectResumeId: "",
    };
  },
  created() {
    this.getDetail();
    this.loadCompanyBaseInfos();
    this.actionUrl = api.ApiBaseUrl + "/api/file/uploadOne";
  },
  methods: {
    editInvoiceInfo(val) {
      this.fileList = [];
      this.form = val;
      this.isRest = false;
      this.isCanCompleteInvoice = true;
      this.canCompleteInvoice = true;
      this.editInvoice = true;
      this.improveBuildInfoTitle = "编辑发票信息";
      (val.invoiceImgPathList || []).forEach((i) => {
        this.fileList.push({
          fileName: i.fileName,
          filePath: i.filePath,
          url: this.ApiBaseUrl + i.filePath,
        });
      });
    },
    // 删除到账信息
    delAccontMoney(id) {
      delAccontMoneyValidate(id)
        .then((res) => {
          if (res.result) {
            this.$confirm("是否删除该项?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              customClass: "altCls",
              type: "warning",
            }).then(() => {
              delAccontMoney(id)
                .then((res1) => {
                  if (res1.success) {
                    this.$message.success(res1.message);
                    this.getDetail();
                  }
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消删除",
                  });
                });
            });
          } else {
            let div =
              "<p style='font-weight:bold'>无法删除</p>" +
              "<div>删除后累计到账金额将小于0</div>";
            this.$confirm(div, "提示", {
              cancelButtonText: "取消",
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
              .then(() => {})
              .catch(() => {});
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 新增退款 编辑退款
    saveRefund() {
      this.saveInfoloading = true;
      this.$refs.refundForm.validate((valid) => {
        if (valid) {
          if (this.refundForm.accountMoney < 0) {
            this.$message.error("退款金额不可以小于0！");
            this.saveInfoloading = false;
            return false;
          }
          this.refundForm.financeTaskId = this.financeTaskId;

          if (this.refundForm.id) {
            this.refundTitle = "编辑退款";
            editRefund(this.refundForm).then((res) => {
              if (res.success) {
                this.saveInfoloading = false;
                this.isCanRefund = false;
                this.$message.success(res.message);
                this.cancel();
                this.getDetail();
              } else {
                this.saveInfoloading = false;
              }
            });
          } else {
            addRefund(this.refundForm).then((res) => {
              if (res.success) {
                this.saveInfoloading = false;
                this.$message.success(res.message);
                this.isCanRefund = false;
                this.cancel();
                this.getDetail();
              } else {
                this.saveInfoloading = false;
              }
            });
          }
        } else {
          this.saveInfoloading = false;
        }
      });
    },
    // 删除退款
    delRefund(id) {
      this.$confirm("是否删除该项?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      }).then(() => {
        deleteRefund(id)
          .then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.getDetail();
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      });
    },
    editReverse(val) {
      this.clearForm();
      this.isRest = true;
      this.version = true;
      this.editInvoice = false;
      this.form.version = val.version;
      this.isCanCompleteInvoice = true;
      this.canCompleteInvoice = true;
      this.improveBuildInfoTitle = "编辑红冲";
      this.form.id = val.id;
      (val.fileList || []).forEach((i) => {
        this.fileList.push({
          fileName: i.fileName,
          filePath: i.filePath,
          url: this.ApiBaseUrl + i.filePath,
        });
      });
      this.form.signCompanyId = val.signCompanyId;
      this.form.invoiceNumber = val.invoiceNumber;
      this.form.invoiceNumber = val.invoiceNumber;
      this.form.noTaxMoney = val.noTaxMoney;
      this.form.invoiceRemark = val.remark;
      this.form.amount = val.amount;
      this.form.actualMakeInvoiceDate = val.invoiceDate;
      this.form.performanceAttributionDate = val.performanceAttributionDate;
    },
    // 退款
    CanRefund() {
      this.getDetail();
      this.isCanRefund = true;
    },
    //删除红冲信息
    delReverse(val) {
      deleteValidate(val).then((res) => {
        if (res.result.canDelete) {
          this.$confirm("是否删除该项?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            customClass: "altCls",
            type: "warning",
          })
            .then(() => {
              deleteReverse(val).then((res1) => {
                if (res1.success) {
                  this.getDetail();
                  this.$message.success(res1.message);
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        } else {
          let div =
            "<div style='color:#FF8421;font-size:16px;font-weight: 800;'><p>如果删除红冲，系统自动计算出该服务费下的有效开票金额和为" +
            res.result.invoiceMoney +
            "元，</p><p style='margin: 10px 0'>注意有效开票金额不能超过服务费" +
            res.result.money +
            "元</p><p>您可以调整服务费后再试</p></div>";
          this.$confirm(div, "提示", {
            cancelButtonText: "取消",
            dangerouslyUseHTMLString: true,
            customClass: "msgClass",
          })
            .then(() => {})
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        }
      });
    },
    clearForm() {
      this.form.signCompanyId = null;
      this.form.invoiceCompany = null;
      this.form.invoiceNumber = null;
      this.form.invoiceRemark = null;
      this.form.accountRemark = null;
      this.form.actualMakeInvoiceDate = date;
      this.form.performanceAttributionDate = date;
      this.form.actualAccountDate = date;
      this.form.accountDate = date;
      this.form.financeTaskId = null;
      this.form.accountMoney = null;
      this.form.remark = null;
      this.form.version = 0;
      this.form.noTaxMoney = null;
      this.form.amount = null;
      this.form.invalid = null;
      this.form.gllueId = null;
      this.form.id = null;
      this.refundForm.accountMoney = null;
      this.refundForm.accountDate = date;
      this.refundForm.performanceAttributionDate = date;
      this.refundForm.financeTaskId = null;
      this.refundForm.id = null;
      this.refundForm.remark = null;
      this.refundForm.version = null;
      this.fileList = [];
      this.form.entryMoney = undefined;
    },
    handleCancel(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
        this.clearForm();
      });
    },
    editAccount(row) {
      this.version = true;
      this.form.actualAccountDate = row.accountDate;
      this.form.entryMoney = row.accountMoney;
      this.form.accountRemark = row.remark;
      this.form.id = row.id;
      this.form.gllueId = row.gllueId;
      this.form.version = row.version;
      this.form.financeTaskId = row.financeTaskId;
      this.form.performanceAttributionDate = row.performanceAttributionDate;
      this.isCanCompleteFinance = true;
      this.titleName = "修改到账信息";
    },
    changeSaveLoading(val) {
      this.saveInfoloading = val;
    },
    // 驳回
    SaveReject() {
      this.saveInfoloading = true;
      this.$refs.form.validate((valid) => {
        if (valid) {
          let queryData = {
            approvalType: 5,
            remark: this.form.remark,
            financeTaskId: this.financeTaskId,
          };
          approvalCancel(queryData).then((res) => {
            if (res.success) {
              this.saveInfoloading = false;
              this.isRejectDialog = false;
              this.cancel();
              this.getDetail();
            } else {
              this.saveInfoloading = false;
            }
          });
        } else {
          this.saveInfoloading = false;
        }
      });
    },
    saveInvalid() {
      this.saveInfoloading = true;
      this.$refs.form.validate((valid) => {
        if (valid) {
          let queryData = {
            approvalType: 6,
            remark: this.form.invalid,
            financeTaskId: this.financeTaskId,
          };
          approvalCancel(queryData).then((res) => {
            if (res.success) {
              this.saveInfoloading = false;
              this.isCanFail = false;
              this.cancel();
              this.getDetail();
            } else {
              this.saveInfoloading = false;
            }
          });
        } else {
          this.saveInfoloading = false;
        }
      });
    },
    // 开票信息 红冲
    saveCanCompleteInvoice() {
      this.saveInfoloading = true;
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.noTaxMoney < 0) {
            this.$message.error("不计税金额不可以小于0！");
            this.saveInfoloading = false;
            return false;
          }
          if (this.form.amount < 0) {
            this.$message.error("计税金额不可以小于0！");
            this.saveInfoloading = false;
            return false;
          }
          if (this.isRest) {
            let queryData = {
              invoiceDate: this.form.actualMakeInvoiceDate,
              performanceAttributionDate:this.form.performanceAttributionDate,
              fileList: [],
              noTaxMoney: this.form.noTaxMoney,
              amount: this.form.amount,
              remark: this.form.invoiceRemark,
              financeTaskId: this.financeTaskId,
              invoiceNumber: this.form.invoiceNumber,
              signCompanyId: this.form.signCompanyId,
              version: this.form.version,
              id: this.form.id,
            };
            for (let i in this.fileList) {
              queryData.fileList.push({
                fileName: this.fileList[i].name || this.fileList[i].fileName,
                filePath: this.fileList[i].response
                  ? this.fileList[i].response.result
                  : this.fileList[i].filePath,
              });
            }
            reserveSave(queryData).then((res) => {
              if (res.success) {
                this.saveInfoloading = false;
                this.isCanCompleteInvoice = false;
                this.cancel();
                this.getDetail();
              } else {
                this.saveInfoloading = false;
              }
            });
          } else {
            let queryData = {
              actualMakeInvoiceDate: this.form.actualMakeInvoiceDate,
              performanceAttributionDate:this.form.performanceAttributionDate,
              invoiceImgPath: [],
              noTaxMoney: this.form.noTaxMoney,
              invoiceCompany: this.form.invoiceCompany,
              invoiceRemark: this.form.invoiceRemark,
              financeTaskId: this.financeTaskId,
              invoiceNumber: this.form.invoiceNumber,
              signCompanyId: this.form.signCompanyId,
            };
            for (let i in this.fileList) {
              queryData.invoiceImgPath.push({
                fileName: this.fileList[i].name || this.fileList[i].fileName,
                filePath: this.fileList[i].response
                  ? this.fileList[i].response.result
                  : this.fileList[i].filePath,
              });
            }
            if (this.editInvoice) {
              editInvoice(queryData).then((res) => {
                this.saveInfoloading = false;
                if (res.success) {
                  this.isCanCompleteInvoice = false;
                  this.$message.success(res.message);
                  this.getDetail();
                  this.isCanCompleteInvoice = false;
                }
              });
            } else {
              completeInvoice(queryData).then((res) => {
                if (res.success) {
                  this.saveInfoloading = false;
                  this.isCanCompleteInvoice = false;
                  this.cancel();
                  this.getDetail();
                } else {
                  this.saveInfoloading = false;
                }
              });
            }
          }
        } else {
          this.saveInfoloading = false;
        }
      });
    },
    //到账
    saveImproveFoo() {
      this.saveInfoloading = true;
      this.$refs.form1.validate((valid) => {
        if (valid) {
          if (this.form.entryMoney < 0) {
            this.$message.error("到账金额不可以小于0！");
            this.saveInfoloading = false;
            return false;
          }
          if (this.version) {
            let queryData = {
              accountDate: this.form.actualAccountDate,
              performanceAttributionDate: this.form.performanceAttributionDate,
              accountMoney: this.form.entryMoney,
              financeTaskId: this.form.financeTaskId,
              gllueId: this.form.financeTaskId,
              id: this.form.id,
              remark: this.form.accountRemark,
              version: this.form.version,
            };
            editFinanceAccountMoneyRecord(queryData).then((res) => {
              if (res.success) {
                this.saveInfoloading = false;
                this.isCanCompleteFinance = false;
                this.cancel();
                this.getDetail();
              } else {
                this.saveInfoloading = false;
              }
            });
          } else {
            let queryData = {
              accountRemark: this.form.accountRemark,
              financeTaskId: this.financeTaskId,
              actualAccountDate: this.form.actualAccountDate,
              performanceAttributionDate: this.form.performanceAttributionDate,
              accountMoney: this.form.entryMoney,
            };
            completeAccount(queryData).then((res) => {
              if (res.success) {
                this.saveInfoloading = false;
                this.isCanCompleteFinance = false;
                this.cancel();
                this.getDetail();
              } else {
                this.saveInfoloading = false;
              }
            });
          }
        } else {
          this.saveInfoloading = false;
        }
      });
    },
    resetFoo() {
      this.isCanCompleteInvoice = true;
      this.canCompleteInvoice = true;
    },
    improveFoo() {
      this.isCanCompleteFinance = true;
    },
    editAccountMoney(status) {
      this.$prompt("修改到账金额", "提示", {
        inputValue: this.info.financeTask.accountMoney + "",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        inputPattern: /(^\d{1,8}$)|(^\d{1,8}\.\d{0,2}$)/,
        inputErrorMessage: "请输入正确格式的到账金额",
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          editAccountMoney({
            accountMoney: value,
            financeId: this.financeTaskId,
            financeTaskStatus: status,
          }).then((res) => {
            if (res.success) {
              this.$message({
                type: "success",
                message: "修改成功",
              });
              this.getDetail();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消成功",
          });
        });
    },
    checkNumber: function (withdrawAmount) {
      content.withdrawAmount =
        withdrawAmount.match(/^\d*(\.?\d{0,2})/g)[0] || null;
    },
    handleDown(row) {
      //下载文件
      location.href =
        api.ApiBaseUrl +
        "/api/file/download?fileName=" +
        encodeURIComponent(row.fileName) +
        "&filePath=" +
        row.filePath;
    },
    //预览
    previewOpen(item) {
      // let item = this.versionVal;
      const fileType =
        item.fileName.split(".")[item.fileName.split(".").length - 1];
      if (
        fileType == "doc" ||
        fileType == "docx" ||
        fileType == "xlsx" ||
        fileType == "pptx" ||
        fileType == "ppt"
      ) {
        //wps预览
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" +
            api.PreviewUrl +
            item.filePath,
          "_blank"
        );
      } else if (fileType == "pdf") {
        //pdf预览
        window.open(
          "/pdf/web/viewer.html?file=" + api.PreviewUrl + item.filePath,
          "_blank"
        );
      } else if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
        //图片预览
        window.open(api.PreviewUrl + item.filePath, "_blank");
      } else if (fileType) {
        this.$message.error("不支持预览");
      }
    },
    reset: function () {
      this.dialogVisible = true;
      this.isfinancialTask = true;
      this.titName = "编辑财务任务";
    },
    cancle: function () {
      this.isReject = false;
      this.invalid = false;
      this.isfinancialTask = false;
      this.canCompleteInvoice = false;
      this.canCompleteFinance = false;
      this.dialogVisible = false;
      this.fileList = [];
    },
    handleSuccess(res, file, fileList) {
      this.fileList = fileList;
    },
    completeInvoice() {},
    saveInfos: function () {
      if (this.isfinancialTask) {
        this.$refs.saveFinancialTask.saveFinancialTask();
        //  this.cancel();
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          let queryData = {
            actualAccountDate: this.form.actualAccountDate,
            actualMakeInvoiceDate: this.form.actualMakeInvoiceDate,
            performanceAttributionDate:this.form.performanceAttributionDate,
            approvalType: null,
            invoiceImgPath: [],
            invoiceNumber: this.form.invoiceNumber,
            financeTaskId: this.financeTaskId,
            signCompanyId: this.form.signCompanyId,
            remark: this.form.remark,
            accountMoney: this.form.entryMoney,
          };
          for (let i in this.fileList) {
            queryData.invoiceImgPath.push({
              fileName: this.fileList[i].name,
              filePath: this.fileList[i].response.result,
            });
          }
          //  approvalType
          if (this.canCompleteFinance) {
            queryData.approvalType = 2;
          } else if (this.canCompleteInvoice) {
            queryData.approvalType = 1;
          } else if (this.isReject) {
            queryData.approvalType = 5;
          } else if (this.invalid) {
            queryData.approvalType = 6;
          }
          this.saveInfoloading = true;
          approvalFinanceApproval(queryData)
            .then((res) => {
              if (res.success) {
                this.cancel();
                this.getDetail();
              }
              this.saveInfoloading = false;
            })
            .catch((_) => {
              this.saveInfoloading = false;
            });
        }
      });
    },
    beforeAvatarUpload: function (file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isLt10M;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleInfoSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    loadCompanyBaseInfos() {
      getCommonSignCompanyList().then((res) => {
        if (res.success) {
          this.companyBaseInfoList = res.result;
        }
      });
    },
    handleClose(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     this.cancel();
      //     done();
      //   })
      //   .catch((_) => {});
      this.cancel();
      done();
    },

    cancel: function () {
      this.isReject = false;
      this.invalid = false;
      this.canCompleteInvoice = false;
      this.canCompleteFinance = false;
      this.isfinancialTask = false;
      this.dialogVisible = false;
      this.fileList = [];
      this.isShowHistory = false;
    },
    getDetail: function () {
      this.listLoading = true;
      getFinanceDetail({
        financeTaskId: this.financeTaskId,
        isFinance: this.isFinance,
      })
        .then((res) => {
          Object.assign(this.info, res.result);
          this.listLoading = false;
        })
        .catch((_) => {
          this.listLoading = false;
        });
    },
    cancelFinanceTask: function () {
      this.$confirm("请确认是否取消发起财务任务？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          approvalCancel({
            approvalType: 4,
            remark: this.form.remark,
            financeTaskId: this.financeTaskId,
          }).then((res) => {
            if (res.success) {
              this.$message({
                type: "success",
                message: "取消成功!",
              });
              this.getDetail();
            }
          });
        })
        .catch(() => {});
    },
    change(e) {
      this.$forceUpdate();
    },
    //查看审批流
    showApprovalFlow() {
      this.approvalFlowDig = true;
      noContractFinanceApprovalFlow({
        financeId: this.financeTaskId,
      }).then((res) => {
        if (res.success) {
          this.approvalFlowList = res.result;
        }
      });
    },
    //显示历史记录
    showHistory() {
      this.isShowHistory = true;
      this.projectResumeId = this.info.financeTask.projectResumeId;
    },
    handleInvoice(){
      this.resetFoo()
      this.clearForm()
      this.isRest = false
      this.improveBuildInfoTitle =  this.info.canCompleteInvoice?'完善开票信息':'完善红冲信息'
    }
  },
};
</script>
<style lang="scss">
.invoiceNumberInput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .el-form-item {
    margin-bottom: 15px!important;
  }
}

.financialRecordDialog,.invoiceNumberInput {
  padding: 15px;
  .el-form-item {
    margin-bottom: 0;
    line-height: 28px;
  }
  .el-form-item__label,
  .el-form-item__content {
    line-height: 28px;
    font-size: 13px;
    color: #606266;
  }
  .el-form-item__content {
    color: #333;
  }
  .contral {
    right: 30px;
    position: absolute;
    color: #999;
    i:hover {
      color: #e60012;
    }
    .icon-kh_gsxq_bj {
      font-size: 16px;
      font-weight: normal;
      margin-right: 15px;
    }
    .el-icon-delete {
      font-size: 18px;
    }
  }
  .titleRecord {
    // overflow:hidden;
    line-height: normal;
    margin-bottom: 15px;
    vertical-align: middle;
    span {
      font-size: 16px;
      line-height: 16px;
    }
  }
  .el-collapse-item__wrap,
  .el-collapse,
  .el-collapse-item__header {
    border: none;
  }
  .el-collapse-item__arrow {
    color: #bbbbbb;
    margin: 0 0 0 auto;
    font-size: 12px;
  }
  .el-collapse-item__header {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    background: #f5f5f5;
    position: relative;
  }
  .el-collapse-item {
    border: 1px solid #eee;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-collapse-item__content {
    border-top: solid 1px #ededed;
    padding: 10px;
    font-size: 13px;
    color: #666666;
    .el-row {
      line-height: 26px;
    }
  }
  .contractInvoices {
    border: solid 1px #ededed;
    border-left: 2px #777788 solid;
    padding: 20px 20px 5px 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
    font-size: 13px;
    .el-col-12 {
      margin-bottom: 15px;
    }
  }
  .approvalTime {
    color: #999;
  }
  .el-timeline-item {
    padding-bottom: 1px;
    div {
      margin-bottom: 4px;
    }
    .el-timeline-item__wrapper {
      margin-bottom: 15px;
    }
  }
}
.msgClass {
  width: 600px;
}
</style>
<style lang="scss" scoped>
.canRecordBox {
  border: 1px solid #eee;
  position: relative;
  line-height: normal;
  .canRecord {
    padding: 0 10px;
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 20px;
    color: #606266;
    font-weight: bold;
    font-size: 14px;
    background: #f5f5f5;
  }
  .canRecordcon {
    font-size: 13px;
    padding: 0 20px;
    max-height: 60vh;
    overflow: auto;
  }
}

.el-row {
  line-height: 40px;
}
.title {
  //   background: #9ea7b4;
  line-height: 40px;
  //   margin-top: 10px;
}

.operateBtn {
  text-align: center;
  border-top: 1px solid #ededed;
  line-height: 50px;
  position: fixed;
  bottom: 20px;
  width: calc(100% - 40px);
  background-color: #f9f9f9;
  .el-button + .el-button {
    margin-left: 8px;
  }
}
</style>